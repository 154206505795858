<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Create Fields Template</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text to="/gpm/form_field_template">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <creator
          :initial="dataItem"
          :docCreate="docCreate"
          @data="save"
        ></creator>
        <!-- end -->

        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Creator from "../_components/creator.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Creator,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    docCreate: true,
  }),
  methods: {
    save(data) {
      const url = `/fields_template`;
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res);
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.$router.push("/gpm/form_field_template");
          } else {
            self.loading = false;
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>